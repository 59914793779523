<section id="add-containers-dlg" class="advancedMode page-layout simple left-sidebar inner-sidebar inner-scroll">
  <div class="uds-page-title">
    {{getTitle()}}
  </div>

  <mat-tab-group [(selectedIndex)]="addContainerByType" (selectedIndexChange)="addContainerByTypeChange()"
    *ngIf="shipmentType === ShipmentType.INTERMODAL_SHIPMENT">
    <mat-tab label="By container #"> &nbsp; </mat-tab>
    <mat-tab label="By booking #"> &nbsp; </mat-tab>
    <mat-tab label="By MBL #"> &nbsp; </mat-tab>
    <mat-tab label="By vessel"> &nbsp; </mat-tab>
  </mat-tab-group>

  <form class="uds-page-content-fill-extra  easy-form dp-white" [formGroup]="f" autocomplete="off" #contentZone *ngIf="f">
    <div class="full-height uds-page-content2">
      <div class="p-40">
        <div formArrayName="containers" class="containers" dp-scroll-to-bottom *ngIf="formContainers">
          <div *ngFor="let container of formContainers.controls; let i = index" [formGroupName]="i" [@fadeInOut]>
            <div>
              <div class="text-capitalize heading-sub-small pt-20 pb-12" fxLayout="row" fxLayoutAlign="space-between center">
                <p>Shipment {{numToWord(i+1)}} <span class="add-carrier" *ngIf="addContainerByType !== AddContainerByTypes.ByVessel && addContainerByType !== AddContainerByTypes.ByOL && addContainerByType !== AddContainerByTypes.ByAir">(Add Carrier Name for faster and enhanced tracking results)</span></p>
                <dp-svg-icon [src]="SvgMap.uds_delete" *ngIf="formContainers.controls.length > 1" (click)="removeOrClearContainer(i)">
                </dp-svg-icon>
              </div>
              <mat-form-field class="required-field awb-no" appearance="outline" *ngIf="addContainerByType===AddContainerByTypes.ByAir" required>
                <mat-label>AWB #</mat-label>
                <input matInput formControlName="awb_number" required (keypress)="numberOnly($event)" (blur)="logEvent('awb_number', container)">
                <mat-error>{{ getErrorMessage(container.get('awb_number')) }}
                </mat-error>
                <mat-hint class="uds-hint">{{getAWBAirport(container.get('awb_number'))}}</mat-hint>
              </mat-form-field>
              <ng-container *ngIf="addContainerByType===AddContainerByTypes.ByAir && i === 0">
                <mat-form-field class="airlineList w-332" appearance="outline" @fadeAnimation>
                  <mat-label>Search Trackable Airline by Name</mat-label>
                  <dp-svg matPrefix [src]="SvgMap.search" class="mr-4"></dp-svg>
                  <input type="text" aria-label="Number" matInput [formControl]="airlineSuggest" cdkFocusInitial [matAutocomplete]="auto">
                  <mat-icon matSuffix>arrow_drop_down</mat-icon>
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let airline of filteredAirlines | async" [value]="airline">
                      {{airline.carrier_name}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </ng-container>
              <mat-form-field class="required-field awb-no w-184" appearance="outline" *ngIf="addContainerByType===AddContainerByTypes.ByOL" required>
                <mat-label>Logistics ID</mat-label>
                <input matInput formControlName="logistics_id" required>
                <mat-error>{{ getErrorMessage(container.get('logistics_id'), 'logistics_id') }}
                </mat-error>
              </mat-form-field>
              <ng-container *ngIf="addContainerByType===AddContainerByTypes.ByOL">
                <mat-form-field class="logisticProviderList w-184" appearance="outline" @fadeAnimation>
                  <mat-label>Logistics Partner</mat-label>
                  <mat-select formControlName="logistics_partner" #logisticProviderSelect [disableOptionCentering]="true" panelClass="no-change ellipsis">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="logisticProviderFilterCtrl" placeholderLabel="Search..."
                                             noEntriesFoundLabel="'no matching logistics partner found'"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let logisticProvider of filteredLogisticsProviders | async" [value]="logisticProvider">
                      {{ logisticProvider }}
                    </mat-option>
                  </mat-select>
                  <button mat-button matSuffix *ngIf="container.get('logistics_partner').value" aria-label="Clear"
                          (click)="deleteLogisticProvider($event, container)" class="deleteCarrier">
                    <mat-icon>close</mat-icon>
                  </button>
                  <mat-error>{{ getErrorMessage(container.get('logistics_partner')) }}
                  </mat-error>
                </mat-form-field>
              </ng-container>
              <mat-form-field class="required-field" appearance="outline" *ngIf="addContainerByType===AddContainerByTypes.ByContainer"
                required>
                <mat-label>Container #</mat-label>
                <input matInput formControlName="container_number" required (blur)="logEvent('container_number', container)">
                <mat-error *ngIf="container.get('container_number').invalid">{{ getErrorMessage(container.get('container_number')) }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="required-field" appearance="outline" *ngIf="addContainerByType===AddContainerByTypes.ByBooking">
                <mat-label>Booking #</mat-label>
                <input matInput formControlName="booking_number" required (blur)="logEvent('booking_number', container)">
                <mat-error *ngIf="container.get('booking_number').invalid">{{ getErrorMessage(container.get('booking_number')) }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="required-field" appearance="outline" *ngIf="addContainerByType===AddContainerByTypes.ByMBL">
                <mat-label>MBL #</mat-label>
                <input matInput formControlName="mbl_number" required (blur)="logEvent('mbl_number', container)">
                <mat-error *ngIf="container.get('mbl_number').invalid">{{ getErrorMessage(container.get('mbl_number')) }}
                </mat-error>
              </mat-form-field>
              <mat-form-field style="margin-left: 10px;" appearance="outline" *ngIf="addContainerByType !== AddContainerByTypes.ByVessel && addContainerByType !== AddContainerByTypes.ByAir && addContainerByType !== AddContainerByTypes.ByOL">
                <mat-label>Carrier</mat-label>
                <mat-select formControlName="ocean_line" #carrierSelect [disableOptionCentering]="true" (selectionChange)="logEvent('ocean_line', container)">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="carrierFilterCtrl" placeholderLabel="Search..."
                      noEntriesFoundLabel="'no matching carrier found'"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let carrier of filteredCarriers | async" [value]="carrier">{{ carrier
                    }}
                  </mat-option>
                </mat-select>
                <button mat-button matSuffix *ngIf="container.get('ocean_line').value" aria-label="Clear"
                  (click)="deleteCarrier($event, container)" class="deleteCarrier">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
              <div *ngIf="addContainerByType===AddContainerByTypes.ByVessel" fxLayout="row wrap" fxLayoutAlign="start start"
                fxLayoutGap="10px grid" >
                <dp-vessel-select [label]="'Vessel IMO.'" (optionChanged)="optionChanged('vessel_number', container,  $event)"
                  fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15"></dp-vessel-select>
                <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15">
                  <mat-label>Vessel name</mat-label>
                  <input matInput formControlName="vessel_name" [readonly]="isVesselNameReadOnly" (blur)="logEvent('vessel_name', container)">
                </mat-form-field>
                <dp-port-select [options]="ports" [label]="'Port of origin'" [placeholder]="'Search port code or name'"
                  (optionChanged)="optionChanged('origin_port_code', container,  $event)" fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31"
                  fxFlex.gt-md="15"></dp-port-select>
                <dp-port-select [options]="ports" [label]="'Port of destination'" [placeholder]="'Search port code or name'"
                  (optionChanged)="optionChanged('destination_port_code', container,  $event)" fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31"
                  fxFlex.gt-md="15"></dp-port-select>
                <mat-form-field class="required-field" appearance="outline"  *ngIf="authService.isTeamFeatureEnabled" fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31"
                  fxFlex.gt-md="15">
                  <mat-label>Visibility Level</mat-label>
                  <mat-select panelClass="no-change" formControlName="visible_to" [disableOptionCentering]="true" (selectionChange)="visibilityChange($event.value, container);logEvent('visible_to', container)">
                    <mat-option *ngFor="let visibility_level of VISIBILITY_LEVELS | keyvalue: asIsOrder" [disabled]="shouldDisableTeams(visibility_level.key)" [value]="visibility_level.key">{{ visibility_level.value }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="required-field w-200" [hidden]="hideTeams(container)" appearance="outline" fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31"
                  fxFlex.gt-md="15">
                  <mat-label>Select Teams</mat-label>
                  <mat-select multiple #teamSelect  panelClass="no-change" formControlName="team_names" [disableOptionCentering]="true"
                    (selectionChange)="logEvent('team_names', container)">
                    <mat-option *ngFor="let team of teams" [value]="team">{{team}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <ng-container *ngIf="addContainerByType !== AddContainerByTypes.ByVessel">
                <mat-form-field class="required-field ml-12" appearance="outline"  *ngIf="authService.isTeamFeatureEnabled">
                  <mat-label>Visibility Level</mat-label>
                  <mat-select panelClass="no-change" formControlName="visible_to" [disableOptionCentering]="true" (selectionChange)="visibilityChange($event.value, container);logEvent('visible_to', container)">
                    <mat-option *ngFor="let visibility_level of VISIBILITY_LEVELS | keyvalue: asIsOrder" [disabled]="shouldDisableTeams(visibility_level.key)" [value]="visibility_level.key">{{ visibility_level.value }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="required-field ml-12 w-200" [hidden]="hideTeams(container)" appearance="outline">
                  <mat-label>Select Teams</mat-label>
                  <mat-select multiple #teamSelect panelClass="no-change" formControlName="team_names" [disableOptionCentering]="true"
                    (selectionChange)="logEvent('team_names', container)">
                    <mat-option *ngFor="let team of teams" [value]="team">{{team}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>


              <div class="components-table-heading pb-8 mt-12">
                <span>
                  Additional Data
                </span>
                <dp-svg [src]="SvgMap.info" w="16"
                 tooltip="{{addContainerByType === AddContainerByTypes.ByAir? 'Provide additional data about your shipment to improve your experience.' : 'Provide additional data about your shipment to speed up the tracking and improve your experience.'}}">
                </dp-svg>
              </div>
              <div class="optional-field" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="10px grid" fxFlexFill>
                <mat-form-field appearance="outline" *ngIf="addContainerByType === AddContainerByTypes.ByVessel" fxFlex.xs="100"
                  fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15">
                  <mat-label>Mode</mat-label>
                  <mat-select formControlName="mode" [disableOptionCentering]="true" panelClass="no-change"
                    (selectionChange)="modeSelectionChange($event.value, container);logEvent('mode', container);">
                    <mat-option *ngFor="let mode of VESSEL_MODES | keyvalue: asIsOrder" [value]="mode.key">{{ mode.value }}</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15"
                  [ngClass]="{'hidden': hideVesselContainer(container)}">
                  <mat-label>Container #</mat-label>
                  <input matInput formControlName="vessel_container" (blur)="logEvent('vessel_container', container)">
                  <mat-error *ngIf="container.get('vessel_container').invalid">{{ getErrorMessage(container.get('vessel_container')) }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15"
                  *ngIf="addContainerByType === AddContainerByTypes.ByVessel">
                  <mat-label>Carrier</mat-label>
                  <input matInput formControlName="vessel_carrier" (blur)="logEvent('vessel_carrier', container)">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15"
                  [ngClass]="{'h-84': container.get('mbl_number').invalid}"
                  *ngIf="addContainerByType !== AddContainerByTypes.ByMBL && addContainerByType !== AddContainerByTypes.ByAir && addContainerByType !== AddContainerByTypes.ByOL">
                  <mat-label>MBL #</mat-label>
                  <input matInput formControlName="mbl_number" (blur)="logEvent('mbl_number', container)">
                  <mat-error *ngIf="container.get('mbl_number').invalid">{{ getErrorMessage(container.get('mbl_number'), 'mbl') }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15"
                  *ngIf="addContainerByType !== AddContainerByTypes.ByBooking && addContainerByType !== AddContainerByTypes.ByAir && addContainerByType !== AddContainerByTypes.ByOL">
                  <mat-label>Booking #</mat-label>
                  <input matInput formControlName="booking_number" (blur)="logEvent('booking_number', container)">
                  <mat-error *ngIf="container.get('booking_number').invalid">{{ getErrorMessage(container.get('booking_number')) }}
                  </mat-error>
                </mat-form-field>

                <div fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15" class="relative" *ngIf="addContainerByType === AddContainerByTypes.ByContainer">
                  <mat-form-field appearance="outline" class="fullWidth">
                    <mat-label>Mode</mat-label>
                    <mat-select formControlName="mode" panelClass="no-change" [disableOptionCentering]="true" (selectionChange)="onModeChnage($event, container);logEvent('mode', container)">
                      <mat-option *ngFor="let mode of shippingModes" [value]="mode.id">{{ mode.value }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15"
                  *ngIf="addContainerByType === AddContainerByTypes.ByContainer">
                  <mat-label>HBL #</mat-label>
                  <input matInput formControlName="hbl_number" [required]="!(!container.get('mode').value || container.get('mode').value === 'FCL')" (blur)="logEvent('hbl_number', container)">
                  <mat-error *ngIf="container.get('hbl_number').invalid">{{ getErrorMessage(container.get('hbl_number')) }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15"
                  *ngIf="addContainerByType !== AddContainerByTypes.ByAir && addContainerByType !== AddContainerByTypes.ByOL">
                  <mat-label>Incoterms</mat-label>
                  <mat-select formControlName="incoterm" #incotermsSelect [disableOptionCentering]="true" panelClass="no-change" (selectionChange)="logEvent('incoterm', container)">
                    <mat-option *ngFor="let incoterm of incoterms | keyvalue" [value]="incoterm.key">{{ incoterm.value }}</mat-option>
                  </mat-select>
                  <button mat-button matSuffix *ngIf="container.get('incoterm').value" aria-label="Clear"
                    (click)="deleteIncoterms($event, container)" class="deleteCarrier">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15">
                  <mat-label>Shipment reference</mat-label>
                  <input matInput formControlName="shipment_reference" (blur)="logEvent('shipment_reference', container)">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15">
                  <mat-label>Shipper</mat-label>
                  <input matInput formControlName="shipper" (blur)="logEvent('shipper', container)">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15">
                  <mat-label>Consignee</mat-label>
                  <input matInput formControlName="consignee" (blur)="logEvent('consignee', container)">
                </mat-form-field>

                <mat-form-field fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15" class="form-group" appearance="outline"
                  *ngIf="addContainerByType !== AddContainerByTypes.ByAir && addContainerByType !== AddContainerByTypes.ByOL" (click)="openDatePicker(promisedEta)">
                  <mat-label>Promised ETA</mat-label>
                  <input matInput formControlName="promised_eta" [matDatepicker]="promisedEta"
                    (dateChange)="handlePromisedEtaChange($event, container);logEvent('promised_eta', container)" />
                  <mat-datepicker-toggle matSuffix [for]="promisedEta">
                    <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #promisedEta disabled="false"></mat-datepicker>
                </mat-form-field>

                <mat-form-field fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15" class="form-group" appearance="outline"
                  *ngIf="addContainerByType !== AddContainerByTypes.ByAir && addContainerByType !== AddContainerByTypes.ByOL"
                  (click)="openDatePicker(promisedEtd)">
                  <mat-label>Promised ETD</mat-label>
                  <input matInput formControlName="promised_etd" [matDatepicker]="promisedEtd"
                    (dateChange)="handlePromisedEtdChange($event, container);logEvent('promised_etd', container)" />
                  <mat-datepicker-toggle matSuffix [for]="promisedEtd">
                    <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #promisedEtd disabled="false"></mat-datepicker>
                </mat-form-field>

                <ng-container *ngIf="addContainerByType === AddContainerByTypes.ByContainer">
                  <mat-form-field fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15" class="form-group" appearance="outline" (click)="openDatePicker(pickerEta)"
                    *ngIf="drayageTrackingEnabled()">
                    <mat-label>Manual ETA</mat-label>
                    <input matInput formControlName="manual_eta" [matDatepicker]="pickerEta" (dateChange)="handleManualEtdChange($event, container);logEvent('manual_eta', container)" />
                    <mat-datepicker-toggle matSuffix [for]="pickerEta">
                      <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #pickerEta disabled="false"></mat-datepicker>
                  </mat-form-field>

                  <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15">
                    <mat-label>Total Pack</mat-label>
                    <input matInput formControlName="total_pack" (blur)="logEvent('total_pack', container)" type="number" min="0" (keypress)="numberOnly($event)">
                  </mat-form-field>

                  <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15">
                    <mat-label>Pack UOM</mat-label>
                    <input matInput formControlName="pack_uom" (blur)="logEvent('pack_uom', container)">
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="addContainerByType === AddContainerByTypes.ByContainer || addContainerByType === AddContainerByTypes.ByAir">
                  <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15">
                    <mat-label>Total Weight</mat-label>
                    <input matInput formControlName="total_weight" (blur)="logEvent('total_weight', container)" type="number" min="0">
                    <div class="weight-uom-error">
                      <mat-error *ngIf="container.hasError('weightfieldsMismatch')">Total Weight is required.</mat-error>
                    </div>
                  </mat-form-field>

                  <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15">
                    <mat-label>Weight UOM</mat-label>
                    <input matInput formControlName="weight_uom" (blur)="logEvent('weight_uom', container)">
                    <div class="weight-uom-error">
                      <mat-error *ngIf="container.hasError('uomfieldsMismatch')">Weight UOM is required.</mat-error>
                    </div>
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="addContainerByType === AddContainerByTypes.ByContainer">
                  <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15">
                    <mat-label>Total Volume</mat-label>
                    <input matInput formControlName="total_volume" (blur)="logEvent('total_volume', container)" type="number" min="0">
                  </mat-form-field>

                  <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15">
                    <mat-label>Volume UOM</mat-label>
                    <input matInput formControlName="volume_uom" (blur)="logEvent('volume_uom', container)">
                  </mat-form-field>
                </ng-container>

                <div fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15" class="relative"
                  *ngIf="addContainerByType === AddContainerByTypes.ByContainer && organization.isSensorDataTrackingEnabled">
                  <mat-form-field appearance="outline" class="fullWidth">
                    <mat-label>Device ID</mat-label>
                    <mat-select panelClass="no-change" formControlName="device_ids" [disableOptionCentering]="true" (selectionChange)="logEvent('device_ids', container)">
                      <mat-option *ngFor="let device of devices" [value]="device.deviceId">{{ getDeviceLabel(device) }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="container.get('device_ids').invalid">{{ getErrorMessage(container.get('device_ids')) }}</mat-error>
                  </mat-form-field>
                  <dp-svg class="append" [src]="SvgMap.info" w="16"
                   tooltip="For multiple devices in the container, please use excel upload with comma separated device IDs">
                  </dp-svg>
                </div>

                <!-- Dynamic Fields for this.shipMetaInfo -->
                <ng-container *ngFor="let metaItem of shipMetaInfo; let j = index">
                  <ng-container *ngIf="metaItem">
                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15">
                      <mat-label>{{ metaItem }}</mat-label>
                      <input matInput [formControlName]="metaItem" >
                    </mat-form-field>
                  </ng-container>
                </ng-container>

                <mat-checkbox [hidden]="true" formControlName="showProducts"></mat-checkbox>
              </div>
              <!-- <div class="control-field">
                      <button type="button" mat-icon-button color="primary" aria-label="Add" (click)="addContainerFormGroup()" tooltip="Add"
                        *ngIf="i === formContainers.controls.length - 1 && formContainers.controls.length <= 10">
                        <mat-icon>add_circle_outline</mat-icon>
                      </button>
                      <button type="button" class="float-left optional-field" mat-icon-button color="primary" aria-label="Expand"
                        (click)="expandContainerDetail(i)" tooltip="Expand/Collapse">
                        <mat-icon>{{getExpandIcon(i)}}</mat-icon>
                      </button>
                      <button type="button" mat-icon-button color="primary" aria-label="Remove/clear" (click)="removeOrClearContainer(i)"
                       tooltip="Remove" [disabled]="formContainers.controls.length === 1">
                        <mat-icon>highlight_off</mat-icon>
                      </button>
                    </div> -->
            </div>
            <div *ngIf="shouldShowContainerDetailRows(i)">
              <div formArrayName="products" class="products no-hint">
                <div class="product" [@fadeInOut] *ngFor="let product of container.controls.products.controls; let j = index"
                  formGroupName="{{j}}" (keydown.enter)="$event.preventDefault()">
                  <div class="components-table-heading pb-12" fxLayout="row" fxLayoutAlign="space-between center">
                    <p> Product {{numToWord(j+1)}}</p>
                    <dp-svg-icon [src]="SvgMap.uds_delete" (click)="removeOrClearProduct(i, j)"></dp-svg-icon>
                  </div>
                  <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                    <mat-form-field appearance="outline" floatLabel='never' fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15">
                      <mat-label>Product</mat-label>
                      <input matInput formControlName="product_number" required>
                      <mat-error>{{getProdErrorMessage(product.get('product_number'))}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel='never' fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15">
                      <mat-label>Quantity</mat-label>
                      <input matInput formControlName="product_quantity" type="number">
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel='never' fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15">
                      <mat-label>Quantity UOM</mat-label>
                      <input matInput formControlName="quantity_uom">
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel='never' fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15">
                      <mat-label>PO #</mat-label>
                      <input matInput formControlName="po_number">
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel='never' fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15">
                      <mat-label>Invoice #</mat-label>
                      <input matInput formControlName="invoice_number">
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15"
                      class="showLabel disabled-date-picker" (click)="openDatePicker(picker3)">
                      <mat-label>Order date</mat-label>
                      <input matInput formControlName="order_date" [matDatepicker]="picker3" disabled />
                      <mat-datepicker-toggle matSuffix [for]="picker3">
                        <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #picker3 disabled="false"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel='never' fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15">
                      <mat-label>Description</mat-label>
                      <input matInput formControlName="product_description">
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel='never' fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15">
                      <mat-label>HS code</mat-label>
                      <input matInput formControlName="hs_code">
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel='never' fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15">
                      <mat-label>Unit price</mat-label>
                      <input matInput type="number" formControlName="unit_price">
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel='never' fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15">
                      <mat-label>Currency</mat-label>
                      <mat-select formControlName="price_currency" #currencySelect [disableOptionCentering]="true">
                        <mat-option>
                          <ngx-mat-select-search [formControl]="currencyFilterCtrl" placeholderLabel="Search..."
                            noEntriesFoundLabel="'no matching currency found'"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let currency of filteredCurrencies | async" [value]="currency.id">{{ currency.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel='never' fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15">
                      <mat-label>Lot code</mat-label>
                      <input matInput formControlName="lot_number">
                    </mat-form-field>
                    <mat-form-field class="disabled-date-picker" appearance="outline" fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31"
                      fxFlex.gt-md="15" (click)="openDatePicker(picker1)">
                      <mat-label>Production date</mat-label>
                      <input matInput formControlName="production_date" [matDatepicker]="picker1" disabled />
                      <mat-datepicker-toggle matSuffix [for]="picker1">
                        <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="47" fxFlex.md="31" fxFlex.gt-md="15"
                      class="showLabel disabled-date-picker" (click)="openDatePicker(picker2)">
                      <mat-label>Expiration date</mat-label>
                      <input matInput formControlName="expiration_date" [matDatepicker]="picker2" disabled />
                      <mat-datepicker-toggle matSuffix [for]="picker2">
                        <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #picker2 disabled="false"></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <svg *ngIf="j !== (container.controls.products.controls.length - 1)" height="1" width="100%" viewBox="0 0 12 1"
                    preserveAspectRatio="none">
                    <path d="M0 0 l12 0" stroke="black" stroke-width="1" stroke-dasharray="6,6" vector-effect="non-scaling-stroke" />
                  </svg>
                  <!-- <div fxFlex="90px" class="sub-control-field">
                          <button type="button" mat-icon-button color="primary" aria-label="Add" (click)="addProductFormGroup(i)"
                           tooltip="Add"
                            *ngIf="j === container['controls'].products.controls.length - 1 && container['controls'].products.controls.length <= 10">
                            <mat-icon>add_circle_outline</mat-icon>
                          </button>
                          <button type="button" mat-icon-button color="primary" aria-label="Remove/clear" (click)="removeOrClearProduct(i, j)"
                           tooltip="Remove" [disabled]="productRowDeleteDisabled(i,j)">
                            <mat-icon>highlight_off</mat-icon>
                          </button>
    
                        </div> -->

                </div>
              </div>
            </div>
            <dp-tags class="tags" [tags]="getTags(container)" [showFullTag]="true" [tagSuggestions]="getSuggestions(container)"
              [busy]="false" (deleteTag)="deleteTag($event, container)" (createTag)="createTag($event, container)"></dp-tags>

            <button mat-flat-button class="easy-button secondary medium" (click)="expandContainerDetail($event, i)">Add Products</button>
            <mat-divider class="shipment-divider" *ngIf="i < (formContainers.controls.length - 1)"></mat-divider>
          </div>
          <div fxLayout="row" fxLayoutAlign="end center">
            <button mat-flat-button class="easy-button secondary medium" type="button" (click)="addContainerFormGroup()"
              [disabled]="formContainers.controls.length >= 10">Add Shipment</button>
          </div>

        </div>
        <div fxLayout="row" fxLayoutAlign="end center">
          <button mat-flat-button class="easy-button medium" type="button" [dpBusy]="isBusy" (click)="addContainers()"
            [disabled]="invalidForm() || !getFormData()">Submit Shipment Details</button>
        </div>
      </div>
    </div>
  </form>
</section>