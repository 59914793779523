import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Port } from '@dp/services/static-data.model';
import { OceanShipmentsApiService } from 'app/ocean-shipments/ocean-shipments-api-service';
import { Vessel } from 'app/shared/shared.model';
import { Observable, Subject } from 'rxjs';
import { startWith, debounceTime, distinctUntilChanged, switchMap, tap, filter, skip } from 'rxjs/operators';

@Component({
  selector: 'dp-vessel-select',
  templateUrl: './vessel-select.component.html',
  styleUrls: ['./vessel-select.component.scss'],
})
export class VesselSelectComponent implements OnInit {
  loading$ = new Subject<boolean>();

  options: string[] = null;
  vesselInput: FormControl = new FormControl('');
  @Input() label = 'Search for a vessel';
  @Output() optionChanged = new EventEmitter<Vessel>();
  filteredOptions$: Observable<Vessel[]>;

  constructor(private service: OceanShipmentsApiService) {}

  ngOnInit() {
    this.filteredOptions$ = this.vesselInput.valueChanges.pipe(
      startWith(''),
      skip(1),
      debounceTime(300),
      distinctUntilChanged(),
      filter((data) => typeof data === 'string'),
      tap((data) => {
        // console.log('search=', data);
        const vessel = {imo: data, name: ''} as Vessel;
        this.optionChanged.emit(vessel);
        if (!data.trim()) this.vesselInput.setErrors({ required: true });
        this.loading$.next(true);
      }),
      switchMap((q) => this.service.getVessels(q)),
      tap((data) => {
        // console.log('result=', data);
        this.loading$.next(false);
      })
    );
  }

  optionSelectedChange($event: MatAutocompleteSelectedEvent) {
    const vessel = $event.option.value as Vessel;
    console.log('vessel: ', vessel);
    this.service.isVesselUnique(vessel).subscribe((res) => {
      if (res?.entityExists) {
        this.optionChanged.emit(null);
        this.vesselInput.setErrors({ hasDuplicates: true });
      } else {
        this.optionChanged.emit(vessel);
        this.vesselInput.setErrors(null);
      }
    });
  }
  allowOnlyNumbers(event: KeyboardEvent): void {
    if (!/^\d$/.test(event.key)) {
      event.preventDefault();
    }
  }
  getErrors(): string {
    return this.vesselInput.hasError('hasDuplicates')
      ? 'This vessel is already in the account.'
      : this.vesselInput.hasError('required')
      ? 'This field is required.'
      : '';
  }
  displayFn(vessel: Vessel): string {
    return vessel ? `${vessel.imo}` : '';
  }
}
