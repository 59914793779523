import { ErrorHandler, Injectable, OnDestroy } from '@angular/core';

declare const newrelic: any;

@Injectable()
export class GlobalErrorHandler implements ErrorHandler, OnDestroy {
  private errorHandler = (event: ErrorEvent) => {
    const errInfo = {
      script: event.filename || 'Unknown script',
      lineno: event.lineno,
      colno: event.colno,
      message: event.message,
      stack: event.error?.stack || 'No stack trace',
    };
    this.logError(event.error, errInfo);
  };

  constructor() {
    window.addEventListener('error', this.errorHandler);
  }

  handleError(error: any): void {
    this.logError(error);
  }

  ngOnDestroy(): void {
    window.removeEventListener('error', this.errorHandler);
  }

  private logError(error: any, additionalInfo: Record<string, any> = {}): void {
    if (typeof newrelic !== 'undefined' && newrelic.noticeError) {
      newrelic.noticeError(error, additionalInfo);
    } else {
      console.error('Global Error Handler:', { error, ...additionalInfo });
    }
  }
}
